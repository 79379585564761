<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="车辆自编号" prop="vehicleNo">
        <a-input v-model="form.vehicleNo" placeholder="请输入车辆自编号 唯一性" :disabled="formType !== 1" />
      </a-form-model-item>
      <a-form-model-item label="车牌号" prop="licensePlateNum">
        <a-input v-model="form.licensePlateNum" placeholder="请输入车牌号" />
      </a-form-model-item>
      <a-form-model-item label="车辆大类" prop="vehicleCategory">
        <a-select placeholder="请选择车辆大类" v-model="form.vehicleCategory">
          <a-select-option v-for="(d, index) in vehicleCategoryOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车辆功能" prop="vehicleFunction">
        <a-select placeholder="请选择车辆功能" v-model="form.vehicleFunction">
          <a-select-option v-for="(d, index) in vehicleFunctionOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否自动驾驶" prop="automaticDriving">
        <a-radio-group v-model="form.automaticDriving" button-style="solid">
          <a-radio-button v-for="(d, index) in automaticDrivingOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="车辆品牌" prop="vehicleBrand">
        <a-select placeholder="请选择车辆品牌" v-model="form.vehicleBrand">
          <a-select-option v-for="(d, index) in vehicleBrandOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车辆型号" prop="vehicleModel">
        <a-input v-model="form.vehicleModel" placeholder="请输入车辆型号" />
      </a-form-model-item>
      <a-form-model-item label="整备质量(Kg)" prop="curbWeight">
        <a-input v-model="form.curbWeight" placeholder="请输入整备质量(Kg)" />
      </a-form-model-item>
      <a-form-model-item label="车辆颜色" prop="vehicleColor">
        <a-select placeholder="请选择车辆颜色" v-model="form.vehicleColor">
          <a-select-option v-for="(d, index) in vehicleColorOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="核定载质量(Kg)" prop="approvedLoad">
        <a-input v-model="form.approvedLoad" placeholder="请输入核定载质量(Kg)" />
      </a-form-model-item>
      <!-- <a-form-model-item label="剩余折旧金额(残值)(万元) 系统自动计算得出" prop="surplusDepreciationAmount">
        <a-input
          v-model="form.surplusDepreciationAmount"
          placeholder="请输入剩余折旧金额(残值)(万元) 系统自动计算得出"
        />
      </a-form-model-item> -->
      <a-form-model-item label="强制报废期截止日期" prop="mandatoryScrapDeadlineDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.mandatoryScrapDeadlineDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="证书保管人" prop="certificateKeeperName">
        <a-select
          show-search
          placeholder="请输入证书保管人姓名查找"
          v-model="form.certificateKeeperName"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleCertificateKeeperSearch"
          @change="handleCertificateKeeperChange"
          :loading="loading"
        >
          <a-select-option v-for="d in certificateKeeperArray" :key="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车架号码" prop="frameNo">
        <a-input v-model="form.frameNo" placeholder="请输入车架号码" />
      </a-form-model-item>
      <a-form-model-item label="发动机号" prop="engineNo">
        <a-input v-model="form.engineNo" placeholder="请输入发动机号" />
      </a-form-model-item>
      <a-form-model-item label="车辆照片" prop="vehiclePhoto">
        <file-upload
          v-model="form.vehiclePhoto"
          :params="paramsVehiclePhoto"
          type="image"
          @getfileName="getfileNameVehiclePhoto"
        ></file-upload>
      </a-form-model-item>
      <a-form-model-item label="出厂日期" prop="manufactureDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.manufactureDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="驱动形式" prop="driveType">
        <a-select placeholder="请选择驱动形式" v-model="form.driveType">
          <a-select-option v-for="(d, index) in driveTypeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="发动机功率(KW)" prop="enginePower">
        <a-input v-model="form.enginePower" placeholder="请输入发动机功率(KW)" />
      </a-form-model-item>
      <a-form-model-item label="电车-电池容量(度)" prop="soc">
        <a-input v-model="form.soc" placeholder="请输入电车-电池容量(度)" />
      </a-form-model-item>
      <a-form-model-item label="发动机排量(L)" prop="engineDisplacement">
        <a-input v-model="form.engineDisplacement" placeholder="请输入发动机排量(L)" />
      </a-form-model-item>
      <a-form-model-item label="主发动机厂牌" prop="mainEngineBrand">
        <a-input v-model="form.mainEngineBrand" placeholder="请输入主发动机厂牌" />
      </a-form-model-item>
      <a-form-model-item label="主发动机型号" prop="mainEngineNo">
        <a-input v-model="form.mainEngineNo" placeholder="请输入主发动机型号" />
      </a-form-model-item>
      <a-form-model-item label="副发动机厂牌" prop="viceEngineBrand">
        <a-input v-model="form.viceEngineBrand" placeholder="请输入副发动机厂牌" />
      </a-form-model-item>
      <a-form-model-item label="副发动机型号" prop="viceEngineNo">
        <a-input v-model="form.viceEngineNo" placeholder="请输入副发动机型号" />
      </a-form-model-item>
      <a-form-model-item label="底盘厂牌" prop="chassisBrand">
        <a-input v-model="form.chassisBrand" placeholder="请输入底盘厂牌" />
      </a-form-model-item>
      <a-form-model-item label="底盘型号" prop="chassisNo">
        <a-input v-model="form.chassisNo" placeholder="请输入底盘型号" />
      </a-form-model-item>
      <a-form-model-item label="轮胎型号" prop="tyreNo">
        <a-input v-model="form.tyreNo" placeholder="请输入轮胎型号" />
      </a-form-model-item>
      <a-form-model-item label="排放标准" prop="emissionStandard">
        <a-select placeholder="请选择排放标准" v-model="form.emissionStandard">
          <a-select-option v-for="(d, index) in emissionStandardOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="推荐燃油标号" prop="recommendFuelGrade">
        <a-select placeholder="请选择推荐燃油标号" v-model="form.recommendFuelGrade">
          <a-select-option v-for="(d, index) in recommendFuelGradeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="车辆权属信息-驾驶员" prop="ownershipName">
        <a-select
          show-search
          placeholder="请输入驾驶员姓名查找"
          v-model="form.ownershipName"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleOwnershipSearch"
          @change="handleOwnershipChange"
          :loading="loading"
        >
          <a-select-option v-for="d in ownershipArray" :key="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="驾驶员手机号" prop="driverPhone">
        <a-input v-model="form.driverPhone" placeholder="请输入驾驶员手机号" />
      </a-form-model-item>
      <a-form-model-item label="核定载员" prop="verificationPassenger">
        <a-input v-model="form.verificationPassenger" placeholder="请输入核定载员" />
      </a-form-model-item>
      <a-form-model-item label="行驶证照片" prop="drivingLicense">
        <file-upload
          v-model="form.drivingLicense"
          :params="paramsDrivingLicense"
          type="image"
          @getfileName="getfileNameDrivingLicense"
        ></file-upload>
      </a-form-model-item>
      <a-form-model-item label="车辆验收表" prop="acceptanceForm">
        <file-upload v-model="form.acceptanceForm" :folder="paramsAcceptanceForm.folder" type="image"></file-upload>
      </a-form-model-item>
      <a-form-model-item label="使用单位" prop="deptId">
        <a-tree-select
          v-model="form.deptId"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="所属单位" prop="belongingToDept">
        <a-tree-select
          v-model="form.belongingToDept"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="deptOptions"
          placeholder="请选择"
          :replaceFields="replaceFields"
          tree-default-expand-all
        >
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="是否租赁" prop="lease">
        <a-radio-group v-model="form.lease" button-style="solid">
          <a-radio-button v-for="(d, index) in leaseOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="购置时间" prop="purchaseDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.purchaseDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="购置价格(万元)" prop="purchasePrice">
        <a-input v-model="form.purchasePrice" placeholder="请输入购置价格(万元)" />
      </a-form-model-item>
      <a-form-model-item label="车辆购置税" prop="purchaseTax">
        <a-input v-model="form.purchaseTax" placeholder="请输入车辆购置税" />
      </a-form-model-item>
      <a-form-model-item label="车辆增值税" prop="valueAddedTax">
        <a-input v-model="form.valueAddedTax" placeholder="请输入车辆增值税" />
      </a-form-model-item>
      <a-form-model-item label="购置发票时间" prop="purchaseInvoiceDate">
        <a-date-picker
          style="width: 100%"
          v-model="form.purchaseInvoiceDate"
          value-format="YYYY-MM-DD HH:mm:ss"
          format="YYYY-MM-DD"
          allow-clear
        />
      </a-form-model-item>
      <a-form-model-item label="发票号" prop="invoiceNo">
        <a-input v-model="form.invoiceNo" placeholder="请输入发票号" />
      </a-form-model-item>
      <a-form-model-item label="当前责任人" prop="employeeName">
        <a-select
          show-search
          placeholder="请输入当前责任人姓名查找"
          v-model="form.employeeName"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          @search="handleEmployeeSearch"
          @change="handleEmployeeChange"
          :loading="loading"
        >
          <a-select-option v-for="d in employeeArray" :key="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否虚拟车" prop="virtualVehicle">
        <a-radio-group v-model="form.virtualVehicle" button-style="solid">
          <a-radio-button v-for="(d, index) in virtualeOptions" :key="index" :value="d.dictValue">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getVehicleAccount, addVehicleAccount, updateVehicleAccount } from '@/api/iot/vehicleAccount'
import { listEmployeeAccount } from '@/api/iot/employeeAccount'
import debounce from 'lodash/debounce'
import { TreeSelect } from 'ant-design-vue'

export default {
  name: 'CreateForm',
  props: {
    deptOptions: {
      type: Array,
      required: true
    },
    vehicleCategoryOptions: {
      type: Array,
      required: true
    },
    vehicleFunctionOptions: {
      type: Array,
      required: true
    },
    automaticDrivingOptions: {
      type: Array,
      required: true
    },
    vehicleBrandOptions: {
      type: Array,
      required: true
    },
    vehicleColorOptions: {
      type: Array,
      required: true
    },
    driveTypeOptions: {
      type: Array,
      required: true
    },
    emissionStandardOptions: {
      type: Array,
      required: true
    },
    recommendFuelGradeOptions: {
      type: Array,
      required: true
    },
    leaseOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
    ATreeSelect: TreeSelect
  },
  data() {
    // 节流控制
    this.handleCertificateKeeperSearch = debounce(this.handleCertificateKeeperSearch, 500)
    this.handleOwnershipSearch = debounce(this.handleOwnershipSearch, 500)
    this.handleEmployeeSearch = debounce(this.handleEmployeeSearch, 500)
    return {
      replaceFields: { children: 'children', title: 'label', key: 'id', value: 'id' },
      loading: false,
      formTitle: '',
      imageDrivingLicense: null,
      imageAcceptanceForm: null,
      imageVehiclePhoto: null,
      // 表单参数
      form: {
        vehicleNo: null,
        licensePlateNum: null,
        vehicleCategory: null,
        vehicleFunction: null,
        automaticDriving: '0',
        vehicleBrand: null,
        vehicleModel: null,
        curbWeight: null,
        vehicleColor: null,
        approvedLoad: null,
        surplusDepreciationAmount: null,
        mandatoryScrapDeadlineDate: null,
        certificateKeeperId: null,
        certificateKeeperName: null,
        frameNo: null,
        engineNo: null,
        vehiclePhoto: null,
        manufactureDate: null,
        driveType: null,
        enginePower: null,
        soc: null,
        engineDisplacement: null,
        mainEngineBrand: null,
        mainEngineNo: null,
        viceEngineBrand: null,
        viceEngineNo: null,
        chassisBrand: null,
        chassisNo: null,
        tyreNo: null,
        emissionStandard: null,
        recommendFuelGrade: null,
        ownershipId: null,
        ownershipName: null,
        driverPhone: null,
        verificationPassenger: null,
        drivingLicense: null,
        acceptanceForm: null,
        deptId: null,
        belongingToDept: null,
        lease: '0',
        purchaseDate: null,
        purchasePrice: null,
        purchaseTax: null,
        valueAddedTax: null,
        purchaseInvoiceDate: null,
        invoiceNo: null,
        status: 0,
        virtualVehicle: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        vehicleNo: [{ required: true, message: '车辆自编号 不能为空', trigger: 'blur' }],
        vehicleCategory: [{ required: true, message: '车辆大类不能为空', trigger: 'change' }],
        vehicleFunction: [{ required: true, message: '车辆功能不能为空', trigger: 'change' }],
        automaticDriving: [{ required: true, message: '是否自动驾驶不能为空', trigger: 'blur' }],
        vehicleBrand: [{ required: true, message: '车辆品牌不能为空', trigger: 'change' }],
        vehicleModel: [{ required: true, message: '车辆型号不能为空', trigger: 'blur' }],
        curbWeight: [{ required: true, message: '整备质量(Kg)不能为空', trigger: 'blur' }],
        vehicleColor: [{ required: true, message: '车辆颜色不能为空', trigger: 'change' }],
        mandatoryScrapDeadlineDate: [{ required: true, message: '强制报废期截止日期不能为空', trigger: 'blur' }],
        frameNo: [{ required: true, message: '车架号码不能为空', trigger: 'blur' }],
        engineNo: [{ required: true, message: '发动机号不能为空', trigger: 'blur' }],
        driveType: [{ required: true, message: '驱动形式不能为空', trigger: 'change' }],
        emissionStandard: [{ required: true, message: '排放标准不能为空', trigger: 'change' }],
        deptId: [{ required: true, message: '使用单位不能为空', trigger: 'blur' }],
        belongingToDept: [{ required: true, message: '所属单位不能为空', trigger: 'blur' }],
        lease: [{ required: true, message: '是否租赁不能为空', trigger: 'blur' }],
        purchaseDate: [{ required: true, message: '购置时间不能为空', trigger: 'blur' }],
        purchasePrice: [{ required: true, message: '购置价格(万元)不能为空', trigger: 'blur' }],
        valueAddedTax: [{ required: true, message: '车辆增值税不能为空', trigger: 'blur' }],
        virtualVehicle: [{ required: true, message: '请选择是否虚拟车辆', trigger: 'change' }]
      },
      virtualeOptions: [
        { dictValue: 1, dictLabel: '是' },
        { dictValue: 0, dictLabel: '否' }
      ]
    }
  },
  filters: {},
  created() {},
  computed: {
    paramsDrivingLicense() {
      return { fileName: 'drivinglicense', type: 'aliyunoss', folder: 'vehicleAccount/' + this.form.vehicleNo }
    },
    paramsAcceptanceForm() {
      return { fileName: 'acceptanceform', type: 'aliyunoss', folder: 'vehicleAccount/' + this.form.vehicleNo }
    },
    paramsVehiclePhoto() {
      return { fileName: this.form.vehicleNo, type: 'aliyunoss', folder: 'vehicleAccount/' + this.form.vehicleNo }
    }
  },
  watch: {},
  mounted() {},
  methods: {
    getfileNameDrivingLicense(fileName) {
      this.imageDrivingLicense = fileName
    },
    getfileNameAcceptanceForm(fileName) {
      this.imageAcceptanceForm = fileName
    },
    getfileNameVehiclePhoto(fileName) {
      this.imageVehiclePhoto = fileName
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        vehicleNo: null,
        licensePlateNum: null,
        vehicleCategory: null,
        vehicleFunction: null,
        automaticDriving: '0',
        vehicleBrand: null,
        vehicleModel: null,
        curbWeight: null,
        vehicleColor: null,
        approvedLoad: null,
        surplusDepreciationAmount: null,
        mandatoryScrapDeadlineDate: null,
        certificateKeeperId: null,
        certificateKeeperName: null,
        frameNo: null,
        engineNo: null,
        vehiclePhoto: null,
        manufactureDate: null,
        driveType: null,
        enginePower: null,
        soc: null,
        engineDisplacement: null,
        mainEngineBrand: null,
        mainEngineNo: null,
        viceEngineBrand: null,
        viceEngineNo: null,
        chassisBrand: null,
        chassisNo: null,
        tyreNo: null,
        emissionStandard: null,
        recommendFuelGrade: null,
        ownershipId: null,
        ownershipName: null,
        driverPhone: null,
        verificationPassenger: null,
        drivingLicense: null,
        acceptanceForm: null,
        deptId: null,
        belongingToDept: null,
        lease: '0',
        purchaseDate: null,
        purchasePrice: null,
        purchaseTax: null,
        valueAddedTax: null,
        purchaseInvoiceDate: null,
        invoiceNo: null,
        status: 0,
        virtualVehicle: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getVehicleAccount(id).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.drivingLicense = this.imageDrivingLicense
          this.form.acceptanceForm = this.imageAcceptanceForm
          this.form.vehiclePhoto = this.imageVehiclePhoto
          const form = JSON.parse(JSON.stringify(this.form))
          if (form.id !== undefined && form.id !== null) {
            updateVehicleAccount(form).then((response) => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addVehicleAccount(form).then((response) => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    },
    handleCertificateKeeperSearch(value) {
      // console.log('handlePersonnelSearch', value)
      const queryParam = {
        name: value
      }
      this.loading = true
      listEmployeeAccount(queryParam).then((response) => {
        this.certificateKeeperArray = response.rows
        this.loading = false
      })
    },
    // 证书保管人ID
    handleCertificateKeeperChange(value) {
      this.form.certificateKeeperId = value
      // 设置姓名
      const tmpArray = this.certificateKeeperArray.filter((p) => p.id === value)
      if (tmpArray.length > 0) {
        this.form.certificateKeeperName = tmpArray[0].name
        this.$forceUpdate()
      }
    },
    handleOwnershipSearch(value) {
      // console.log('handlePersonnelSearch', value)
      const queryParam = {
        name: value
      }
      this.loading = true
      listEmployeeAccount(queryParam).then((response) => {
        this.ownershipArray = response.rows
        this.loading = false
      })
    },
    handleOwnershipChange(value) {
      this.form.ownershipId = value
      // 设置姓名
      const tmpArray = this.ownershipArray.filter((p) => p.id === value)
      if (tmpArray.length > 0) {
        this.form.ownershipName = tmpArray[0].name
        this.$forceUpdate()
      }
    },
    handleEmployeeSearch(value) {
      // console.log('handlePersonnelSearch', value)
      const queryParam = {
        name: value
      }
      this.loading = true
      listEmployeeAccount(queryParam).then((response) => {
        this.employeeArray = response.rows
        this.loading = false
      })
    },
    handleEmployeeChange(value) {
      this.form.employeeId = value
      // 设置姓名
      const tmpArray = this.employeeArray.filter((p) => p.id === value)
      if (tmpArray.length > 0) {
        this.form.employeeName = tmpArray[0].name
        this.$forceUpdate()
      }
    }
  }
}
</script>
