var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
      },
      on: { close: _vm.onClose },
    },
    [
      _c("a-divider", { attrs: { orientation: "left" } }, [
        _c("b", [_vm._v(_vm._s(_vm.formTitle))]),
      ]),
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆自编号", prop: "vehicleNo" } },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入车辆自编号 唯一性",
                  disabled: _vm.formType !== 1,
                },
                model: {
                  value: _vm.form.vehicleNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleNo", $$v)
                  },
                  expression: "form.vehicleNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车牌号", prop: "licensePlateNum" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车牌号" },
                model: {
                  value: _vm.form.licensePlateNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "licensePlateNum", $$v)
                  },
                  expression: "form.licensePlateNum",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆大类", prop: "vehicleCategory" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择车辆大类" },
                  model: {
                    value: _vm.form.vehicleCategory,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleCategory", $$v)
                    },
                    expression: "form.vehicleCategory",
                  },
                },
                _vm._l(_vm.vehicleCategoryOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆功能", prop: "vehicleFunction" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择车辆功能" },
                  model: {
                    value: _vm.form.vehicleFunction,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleFunction", $$v)
                    },
                    expression: "form.vehicleFunction",
                  },
                },
                _vm._l(_vm.vehicleFunctionOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否自动驾驶", prop: "automaticDriving" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.automaticDriving,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "automaticDriving", $$v)
                    },
                    expression: "form.automaticDriving",
                  },
                },
                _vm._l(_vm.automaticDrivingOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆品牌", prop: "vehicleBrand" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择车辆品牌" },
                  model: {
                    value: _vm.form.vehicleBrand,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleBrand", $$v)
                    },
                    expression: "form.vehicleBrand",
                  },
                },
                _vm._l(_vm.vehicleBrandOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆型号", prop: "vehicleModel" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆型号" },
                model: {
                  value: _vm.form.vehicleModel,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehicleModel", $$v)
                  },
                  expression: "form.vehicleModel",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "整备质量(Kg)", prop: "curbWeight" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入整备质量(Kg)" },
                model: {
                  value: _vm.form.curbWeight,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "curbWeight", $$v)
                  },
                  expression: "form.curbWeight",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆颜色", prop: "vehicleColor" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择车辆颜色" },
                  model: {
                    value: _vm.form.vehicleColor,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "vehicleColor", $$v)
                    },
                    expression: "form.vehicleColor",
                  },
                },
                _vm._l(_vm.vehicleColorOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "核定载质量(Kg)", prop: "approvedLoad" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入核定载质量(Kg)" },
                model: {
                  value: _vm.form.approvedLoad,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "approvedLoad", $$v)
                  },
                  expression: "form.approvedLoad",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "强制报废期截止日期",
                prop: "mandatoryScrapDeadlineDate",
              },
            },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.mandatoryScrapDeadlineDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mandatoryScrapDeadlineDate", $$v)
                  },
                  expression: "form.mandatoryScrapDeadlineDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "证书保管人", prop: "certificateKeeperName" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    placeholder: "请输入证书保管人姓名查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleCertificateKeeperSearch,
                    change: _vm.handleCertificateKeeperChange,
                  },
                  model: {
                    value: _vm.form.certificateKeeperName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "certificateKeeperName", $$v)
                    },
                    expression: "form.certificateKeeperName",
                  },
                },
                _vm._l(_vm.certificateKeeperArray, function (d) {
                  return _c("a-select-option", { key: d.id }, [
                    _vm._v(" " + _vm._s(d.name) + " "),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车架号码", prop: "frameNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车架号码" },
                model: {
                  value: _vm.form.frameNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "frameNo", $$v)
                  },
                  expression: "form.frameNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发动机号", prop: "engineNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入发动机号" },
                model: {
                  value: _vm.form.engineNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "engineNo", $$v)
                  },
                  expression: "form.engineNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆照片", prop: "vehiclePhoto" } },
            [
              _c("file-upload", {
                attrs: { params: _vm.paramsVehiclePhoto, type: "image" },
                on: { getfileName: _vm.getfileNameVehiclePhoto },
                model: {
                  value: _vm.form.vehiclePhoto,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "vehiclePhoto", $$v)
                  },
                  expression: "form.vehiclePhoto",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "出厂日期", prop: "manufactureDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.manufactureDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "manufactureDate", $$v)
                  },
                  expression: "form.manufactureDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "驱动形式", prop: "driveType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择驱动形式" },
                  model: {
                    value: _vm.form.driveType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "driveType", $$v)
                    },
                    expression: "form.driveType",
                  },
                },
                _vm._l(_vm.driveTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发动机功率(KW)", prop: "enginePower" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入发动机功率(KW)" },
                model: {
                  value: _vm.form.enginePower,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "enginePower", $$v)
                  },
                  expression: "form.enginePower",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "电车-电池容量(度)", prop: "soc" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入电车-电池容量(度)" },
                model: {
                  value: _vm.form.soc,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "soc", $$v)
                  },
                  expression: "form.soc",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发动机排量(L)", prop: "engineDisplacement" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入发动机排量(L)" },
                model: {
                  value: _vm.form.engineDisplacement,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "engineDisplacement", $$v)
                  },
                  expression: "form.engineDisplacement",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "主发动机厂牌", prop: "mainEngineBrand" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入主发动机厂牌" },
                model: {
                  value: _vm.form.mainEngineBrand,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mainEngineBrand", $$v)
                  },
                  expression: "form.mainEngineBrand",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "主发动机型号", prop: "mainEngineNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入主发动机型号" },
                model: {
                  value: _vm.form.mainEngineNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "mainEngineNo", $$v)
                  },
                  expression: "form.mainEngineNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "副发动机厂牌", prop: "viceEngineBrand" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入副发动机厂牌" },
                model: {
                  value: _vm.form.viceEngineBrand,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "viceEngineBrand", $$v)
                  },
                  expression: "form.viceEngineBrand",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "副发动机型号", prop: "viceEngineNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入副发动机型号" },
                model: {
                  value: _vm.form.viceEngineNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "viceEngineNo", $$v)
                  },
                  expression: "form.viceEngineNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "底盘厂牌", prop: "chassisBrand" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入底盘厂牌" },
                model: {
                  value: _vm.form.chassisBrand,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "chassisBrand", $$v)
                  },
                  expression: "form.chassisBrand",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "底盘型号", prop: "chassisNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入底盘型号" },
                model: {
                  value: _vm.form.chassisNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "chassisNo", $$v)
                  },
                  expression: "form.chassisNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "轮胎型号", prop: "tyreNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入轮胎型号" },
                model: {
                  value: _vm.form.tyreNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tyreNo", $$v)
                  },
                  expression: "form.tyreNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "排放标准", prop: "emissionStandard" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择排放标准" },
                  model: {
                    value: _vm.form.emissionStandard,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "emissionStandard", $$v)
                    },
                    expression: "form.emissionStandard",
                  },
                },
                _vm._l(_vm.emissionStandardOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "推荐燃油标号", prop: "recommendFuelGrade" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择推荐燃油标号" },
                  model: {
                    value: _vm.form.recommendFuelGrade,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "recommendFuelGrade", $$v)
                    },
                    expression: "form.recommendFuelGrade",
                  },
                },
                _vm._l(_vm.recommendFuelGradeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆权属信息-驾驶员", prop: "ownershipName" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    placeholder: "请输入驾驶员姓名查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleOwnershipSearch,
                    change: _vm.handleOwnershipChange,
                  },
                  model: {
                    value: _vm.form.ownershipName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "ownershipName", $$v)
                    },
                    expression: "form.ownershipName",
                  },
                },
                _vm._l(_vm.ownershipArray, function (d) {
                  return _c("a-select-option", { key: d.id }, [
                    _vm._v(" " + _vm._s(d.name) + " "),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "驾驶员手机号", prop: "driverPhone" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入驾驶员手机号" },
                model: {
                  value: _vm.form.driverPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "driverPhone", $$v)
                  },
                  expression: "form.driverPhone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "核定载员", prop: "verificationPassenger" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入核定载员" },
                model: {
                  value: _vm.form.verificationPassenger,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "verificationPassenger", $$v)
                  },
                  expression: "form.verificationPassenger",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "行驶证照片", prop: "drivingLicense" } },
            [
              _c("file-upload", {
                attrs: { params: _vm.paramsDrivingLicense, type: "image" },
                on: { getfileName: _vm.getfileNameDrivingLicense },
                model: {
                  value: _vm.form.drivingLicense,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "drivingLicense", $$v)
                  },
                  expression: "form.drivingLicense",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆验收表", prop: "acceptanceForm" } },
            [
              _c("file-upload", {
                attrs: {
                  folder: _vm.paramsAcceptanceForm.folder,
                  type: "image",
                },
                model: {
                  value: _vm.form.acceptanceForm,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "acceptanceForm", $$v)
                  },
                  expression: "form.acceptanceForm",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "使用单位", prop: "deptId" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.deptId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "deptId", $$v)
                  },
                  expression: "form.deptId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "所属单位", prop: "belongingToDept" } },
            [
              _c("a-tree-select", {
                staticStyle: { width: "100%" },
                attrs: {
                  "dropdown-style": { maxHeight: "400px", overflow: "auto" },
                  "tree-data": _vm.deptOptions,
                  placeholder: "请选择",
                  replaceFields: _vm.replaceFields,
                  "tree-default-expand-all": "",
                },
                model: {
                  value: _vm.form.belongingToDept,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "belongingToDept", $$v)
                  },
                  expression: "form.belongingToDept",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否租赁", prop: "lease" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.lease,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "lease", $$v)
                    },
                    expression: "form.lease",
                  },
                },
                _vm._l(_vm.leaseOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "购置时间", prop: "purchaseDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.purchaseDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purchaseDate", $$v)
                  },
                  expression: "form.purchaseDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "购置价格(万元)", prop: "purchasePrice" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入购置价格(万元)" },
                model: {
                  value: _vm.form.purchasePrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purchasePrice", $$v)
                  },
                  expression: "form.purchasePrice",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆购置税", prop: "purchaseTax" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆购置税" },
                model: {
                  value: _vm.form.purchaseTax,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purchaseTax", $$v)
                  },
                  expression: "form.purchaseTax",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "车辆增值税", prop: "valueAddedTax" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入车辆增值税" },
                model: {
                  value: _vm.form.valueAddedTax,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "valueAddedTax", $$v)
                  },
                  expression: "form.valueAddedTax",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "购置发票时间", prop: "purchaseInvoiceDate" } },
            [
              _c("a-date-picker", {
                staticStyle: { width: "100%" },
                attrs: {
                  "value-format": "YYYY-MM-DD HH:mm:ss",
                  format: "YYYY-MM-DD",
                  "allow-clear": "",
                },
                model: {
                  value: _vm.form.purchaseInvoiceDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "purchaseInvoiceDate", $$v)
                  },
                  expression: "form.purchaseInvoiceDate",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "发票号", prop: "invoiceNo" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入发票号" },
                model: {
                  value: _vm.form.invoiceNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "invoiceNo", $$v)
                  },
                  expression: "form.invoiceNo",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "当前责任人", prop: "employeeName" } },
            [
              _c(
                "a-select",
                {
                  attrs: {
                    "show-search": "",
                    placeholder: "请输入当前责任人姓名查找",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleEmployeeSearch,
                    change: _vm.handleEmployeeChange,
                  },
                  model: {
                    value: _vm.form.employeeName,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "employeeName", $$v)
                    },
                    expression: "form.employeeName",
                  },
                },
                _vm._l(_vm.employeeArray, function (d) {
                  return _c("a-select-option", { key: d.id }, [
                    _vm._v(" " + _vm._s(d.name) + " "),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否虚拟车", prop: "virtualVehicle" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.virtualVehicle,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "virtualVehicle", $$v)
                    },
                    expression: "form.virtualVehicle",
                  },
                },
                _vm._l(_vm.virtualeOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }